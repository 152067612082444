
      import {mdx} from '@mdx-js/react';

      /* @jsx mdx */
import {CmsPagePageLayout} from 'layouts/CmsPagePageLayout'


const layoutProps = {
  
};
const MDXLayout = CmsPagePageLayout
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The websites of the `}<em parentName="p">{`next-generation`}</em>{` are `}<strong parentName="p">{`static websites`}</strong>{`.`}</p>
    <p>{`They’re perfect for creating both `}<strong parentName="p">{`simple `}<em parentName="strong">{`brochure websites`}</em></strong>{` and `}<strong parentName="p">{`more complex portals, like `}<em parentName="strong">{`blogs`}</em>{` and `}<em parentName="strong">{`online shops`}</em></strong>{`.`}</p>
    <p>{`Don’t be scared, you’ll find it easy to write content. The classic administration tools you’re used to are there, just like in dynamic websites.`}</p>
    <p>{`I’d like to avoid some misunderstanding: `}<strong parentName="p">{`a static website can host animations and can interact with visitors dynamically according to your needs`}</strong>{`. The words `}<em parentName="p">{`static`}</em>{` and `}<em parentName="p">{`dynamic`}</em>{` refer to how a website is built in its foundation; they don’t describe the characteristics of its contents.`}</p>
    <p>{`Let’s see now why a static website could be the best choice.`}</p>
    <h2>{`1. Speed`}</h2>
    <p>{`Having a fast website makes the difference. Your potential clients will open the next result on Google with negative consequences for your brand when a page doesn’t load quickly enough.`}</p>
    <p>{`Static websites are the fastest. Their pages load almost instantaneously and the navigation doesn’t lag.`}</p>
    <h2>{`2. Security`}</h2>
    <p>{`A static website is safer. There are fewer probabilities of finding bugs. Plus, hackers won’t have room to attack you and can’t gain access to your sensitive data.`}</p>
    <h2>{`3. Reduced costs`}</h2>
    <p>{`Managing and maintaining a static website costs less because its structure is simple and lean. We also have cost-effective solutions for publishing.`}</p>
    <h2>{`4. Optimized images`}</h2>
    <p>{`Your visitors will always see perfect photos from their smartphones, tablets and desktops. That’s because our static websites optimize their resolution automatically for all devices.`}</p>
    <h2>{`5. Versions`}</h2>
    <p>{`Have you deleted some pages by mistake? This is not a problem anymore because you can restore everything. Are you not happy with the latest changes you made? You can go back and start over.`}</p>
    <p>{`A static website keeps a history of its evolution through time.`}</p>
    <h2>{`6. Easier maintenance`}</h2>
    <p>{`A static website is easier to maintain because it needs just a web server to work.`}</p>
    <p>{`On the contrary, a dynamic website needs a web server, a back-end language generating pages for each and every visit and a database where is contents are saved.`}</p>
    <h2>{`7. Modularity`}</h2>
    <p>{`Attaching a `}<em parentName="p">{`database`}</em>{` to save information collected from your visitors. Accepting payments as an `}<em parentName="p">{`e-commerce`}</em>{`. Querying external `}<em parentName="p">{`web services`}</em>{`. Connecting `}<em parentName="p">{`third party software`}</em>{` like your ERP.`}</p>
    <p>{`A static website is capable of performing a multitude of complex tasks.`}</p>
    <h2>{`8. Scalability`}</h2>
    <p>{`A static website will never lose its typical speed if the number of your visitors increases. The cloud will optimize it according to traffic variations.`}</p>
    <p>{`Would you like to learn more? Feel free to `}<a parentName="p" {...{
        "href": "contact"
      }}>{`contact me`}</a>{`.`}</p>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
    